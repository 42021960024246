import 'core-js/features/object/assign';
import 'core-js/features/object/keys';
import 'core-js/features/object/entries';
import 'core-js/features/object/values';
import 'core-js/features/string/ends-with';
import 'core-js/features/string/starts-with';
import 'core-js/features/string/includes';
import 'core-js/features/string/repeat';
import 'core-js/features/array/includes';
import 'core-js/features/array/find';
import 'core-js/features/array/fill';

import 'whatwg-fetch';
import 'objectFitPolyfill';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import 'intersection-observer';

if (!('remove' in Element.prototype)) {
  Element.prototype.remove = function() {
    if (this.parentNode) {
      this.parentNode.removeChild(this);
    }
  };
}
